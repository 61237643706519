
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddInteractiveActivitiesModal from "@/views/interactive-marketing/interactive-activities/AddInteractiveActivitiesModal.vue";
import FilterDropdwon from "@/views/interactive-marketing/interactive-activities/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiSalesInteractions } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface SalesInteractions {
  id: number;
  name: string;
  description: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "interactive-activities",
  components: {
    MBDatatable,
    FilterDropdwon,
    AddInteractiveActivitiesModal,
  },
  setup() {
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<SalesInteractions>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExport = ref<boolean>(false);
    const disabledExportMonthKpi = ref<boolean>(false);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const { t } = useI18n();
    const tableHeader = ref([
      // {
      //   name: "ID",
      //   key: "id",
      //   sortable: true,
      // },

      {
        name: t("interactiveActivities.influencer"),
        key: "account_id",
        sortable: false,
      },
      {
        name: t("interactiveActivities.targetName"),
        key: "target_name",
        sortable: false,
      },
      {
        name: t("interactiveActivities.start"),
        key: "start",
        sortable: true,
      },
      {
        name: t("interactiveActivities.closeDate"),
        key: "close_date",
        sortable: true,
      },
      {
        name: t("interactiveActivities.stage"),
        key: "stage",
        sortable: false,
      },
      {
        name: t("interactiveActivities.targetAmount"),
        key: "target_amount",
        sortable: false,
        align: "right",
      },
      {
        name: t("interactiveActivities.completion"),
        key: "completion",
        sortable: false,
      },
      {
        name: "", // t("common.actions")
        key: "actions",
        sortable: false,
      },
    ]);
    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSalesInteractionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const fileExportSalesInteractionsMonthKpi = () => {
      disabledExportMonthKpi.value = true;
      // search_value: search.value,
      //   filter_group: filterOptions.value,
      //   sort_orders: sortOptions.value,
      //   page: currentPage.value,
      //   page_size: pageSize.value,
      ApiSalesInteractions.exportSalesInteractionsMonthKpi({})
        .then((data) => {
          disabledExportMonthKpi.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExportMonthKpi.value = false;
          console.log(error);
        });
    };

    const getSalesInteractionsList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiSalesInteractions.getSalesInteractionsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "") {
          if (item == "start") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "gteq",
            });
          } else if (item == "close_date") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "lteq",
            });
          } else if (item == "target_name") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getSalesInteractionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const stageClass = (status) => {
      let statusClass = "";
      switch (status) {
        case "40":
          statusClass = "badge-light-info";
          break;
        case "10":
          statusClass = "badge-light-warning";
          break;
        case "20":
          statusClass = "badge-light-success";
          break;
        case "50":
        case "30":
          statusClass = "badge-light-danger";
          break;
        default:
          break;
      }
      return statusClass;
    };

    const handleFilterReset = () => {
      resetFilter();
      getSalesInteractionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSalesInteractionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getSalesInteractionsList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getSalesInteractionsList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getSalesInteractionsList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getSalesInteractionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const getProgressStatus = (val) => {
      if (val < 50) {
        return "exception";
      } else if (val >= 50 && val < 80) {
        return "warning";
      } else if (val >= 80 && val < 100) {
        return "";
      } else if (val >= 100) {
        return "success";
      }
    };

    return {
      t,
      formatDate,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExport,
      disabledExportMonthKpi,
      stageClass,
      fileExportSalesInteractionsMonthKpi,
      getSalesInteractionsList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateList,
      getProgressStatus,
    };
  },
});
