
import { ApiBase } from "@/core/api";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  target_name: string;
  target_type: string;
  start: string;
  close_date: string;
}

export default defineComponent({
  name: "social-connections-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      target_name: "",
      target_type: "",
      start: "",
      close_date: "",
    });
    const { t } = useI18n();
    const typeOptions = ref([]);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["sales_interactions_target_type"],
      });
      if (data.code == 0) {
        typeOptions.value = data.data.sales_interactions_target_type.items;
      }
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });

    return {
      data,
      formRef,
      typeOptions,
      t,
      submit,
      handleReset,
    };
  },
});
